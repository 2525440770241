<template>
  <v-dialog
    v-model="remanentDetail.showModal"
    :scrollable="isMobile"
    :persistent="!isMobile"
    :hide-overlay="isMobile"
    :transition="isMobile ? 'dialog-bottom-transition' : ''"
    :max-width="$vuetify.breakpoint.smAndDown ? '350px' : '700px'"
  >
    <v-card>
      <v-card-title class="border-title">
        <v-row no-gutters>
          <v-col style="position: relative;">
            <span class="text-h4" :style="{'color': $vuetify.breakpoint.smAndDown ? '#1F3261' : '#101e3e;'}">{{$t('remanentDetail.modal.title')}}</span>
            <v-btn fab absolute right icon @click="close" height="24" width="24" style="right: 0px">
              <v-icon size="24" color="#101e3e">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
            <v-row v-if="$vuetify.breakpoint.smAndDown">
              <v-col>
                <v-select
                  hide-details="auto"
                  no-data-text=""
                  :label="$t('remanentDetail.modal.coverageLabel')"
                  item-text="text"
                  clearable
                  @change="changeInput"
                  :items="options"
                  item-value="slug"
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="ma-auto pt-6" style="border-bottom-style: solid;border-bottom-width: 2px;">
              <v-col class="pa-0">
                <RemanentGraph v-if="graphData"
                  ref="barGraph"
                  :graphData="graphData"
                  :graphId="Math.random() * (1000 - 1) + 1" 
                  type="bar" 
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex flex-column align-center pt-4">
                <v-row no-gutters>
                  <v-col class="text-center" style="line-height: 1.2;">
                    <span class="text-body-1" style="color: #2a2a2a;">{{ usedText }}</span>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col style="line-height: 1.2;">
                    <span class="text-caption" style="color: #949494;">{{ availableText }}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" v-if="!$vuetify.breakpoint.smAndDown">
            <v-list-item-group
              active-class="item-active"
              color="primary"
            >
              <v-list-item v-for="(item, i) in options" :key="i" @click="changeInput(item.slug)">
                <v-list-item-avatar >
                  <v-icon 
                    size="16"
                    :class="{'grey lighten-4': activeIndex !== i, 'primary': activeIndex === i}" 
                    :color="activeIndex === i ? '' : 'primary'"
                    :dark="activeIndex === i"
                    v-text="item.icon">
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="pa-0">
                  <v-row class="ma-auto">
                    <v-col cols="8">
                      <v-row>
                        <span class="text-subtitle-2" style="color: #1f3261;line-height: 1.2;">{{ item.text }}</span>
                      </v-row>
                      <v-row>
                        <span class="text-body-1" style="line-height: 1.2; color: #2a2a2a;">{{ item.currency }} {{ item.usedAmount }}</span>
                      </v-row>
                    </v-col>
                    <v-col cols="4" class="d-flex align-center pa-1">
                      <span class="text-body-1" style="font-weight: 600;color: #2a2a2a;">{{ getPercentText(item) }}</span>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import RemanentGraph from '../general/RemanentGraph'
import arcService from '@/services/arc-api.service'
import plansService from '@/services/plans-api.service'
import { createNamespacedHelpers } from 'vuex'
const authorizationModule = createNamespacedHelpers('authorization');

export default {
  name: 'RemanentDetail',
  components: { RemanentGraph },
  props: {
    remanentDetail: {
      type: Object,
      default: () => ({
        showModal: false,
      })
    }
  },
  data() {
    return {
      data: null,
      currentData: {},
      activeCoverage: null
    }
  },
  mounted() {
    const barData = {};
    let labels = [];
    let datasets = plansService.getDefaultDetailDatasets();
    plansService.getDetailData(labels, datasets, this.coverageBalanceDetail)
    barData['labels'] = labels;
    barData['datasets'] = datasets;
    this.currentData = {...this.generalBalance, availableBenefit: this.generalBalance.maximumBenefitAvailable};
    this.data = barData;
  },
  methods: {
    close() {
      this.remanentDetail.showModal = false;
    },
    changeInput(slug) {
      const colorConfig = plansService.getStatusConfiguration('USED')
      let index = this.options.findIndex(option => option.slug === slug)
      if (this.activeCoverage === slug) {
        index = -1;
        this.activeCoverage = null;
      } else {
        this.activeCoverage = slug;
      }
      
      const barData = this.data;
      for (var i = 0; i < barData.datasets[0].backgroundColor.length; i++) {
        if (index === i || index < 0 ) {
          barData.datasets[0].backgroundColor[i] = colorConfig.color;
        } else {
          barData.datasets[0].backgroundColor[i] = colorConfig.colorTransparent;
        }
      }
      if (index < 0) {
        this.currentData = {...this.generalBalance, availableBenefit: this.generalBalance.maximumBenefitAvailable};
      } else {
        this.currentData = this.coverageBalanceDetail.find(coverage => coverage.coverageType === slug);
      }
      this.data = barData;
      if (this.$refs.barGraph) {
        this.$refs.barGraph.updateGraph();
      } 
    },
    getPercentText(item) {
      return (item.usedAmountPercent === '100.00' ? parseFloat(item.usedAmountPercent).toFixed(0) : item.usedAmountPercent).concat(' %')
    }
  },
  computed: {
    ...authorizationModule.mapGetters(['coverageBalanceDetail', 'generalBalance']),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    options() {
      return this.coverageBalanceDetail.map(coverageBalance => {
        const coverageType = arcService.getTypeObject(coverageBalance.coverageType);
        const usedAmount = coverageBalance.maximumBenefit - coverageBalance.availableBenefit;
        return {
          slug: coverageBalance.coverageType,
          text: this.$t(coverageType.text),
          icon: coverageType.icon,
          currency: coverageBalance.currencyCode,
          usedAmount: usedAmount.toLocaleString(),
          usedAmountPercent: (( usedAmount / coverageBalance.maximumBenefit) * 100).toFixed(2)
        }
      })
    },
    activeIndex() {
      return this.options.findIndex(option => option.slug === this.activeCoverage)
    },
    usedText() {
      const usedAmount = (this.currentData.maximumBenefit - this.currentData.availableBenefit)

      let usedFixedAmount = 0;

      if(usedAmount > 0 && this.currentData.maximumBenefit > 0){
        usedFixedAmount = (( usedAmount / this.currentData.maximumBenefit) * 100).toFixed(0)
      }

      let text = usedFixedAmount.toString()
      text = text.concat('% ', this.$t('remanentDetail.modal.graph.used'), ' / ', this.currentData.currencyCode, ' ', parseFloat(usedAmount).toLocaleString())
      return text;
    },
    availableText() {
      const availableAmount = this.currentData.availableBenefit;
      return ''.concat(this.$t('remanentDetail.modal.graph.available'), ' ', this.currentData.currencyCode, ' ', parseFloat(availableAmount).toLocaleString());
    },
    graphData: {
      get() {
        return this.data;
      },
      set(value) {
        this.data = value;
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.border-title {
  border-bottom-style: solid;
  border-bottom-width: 1.4px;
  border-bottom-color: #e0e0e0;
}
.item-active {
  border-radius: 8px !important;
  box-shadow: 0 8px 16px 0 rgba(0, 74, 148, 0.13) !important;
}
</style>