<template>
  <div>
    <v-row v-if="subtitle">
      <v-col class="pb-0">
        <span class="osg-gray--text text-subtitle-2">{{subtitle}}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="d-flex justify-center">
          <v-progress-circular
            v-if="loading"
            :size="70"
            class="text-center"
            :width="7"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
         
        <div v-show="!loading">
          <v-data-table
            v-if="authorizations.length > 0 "
            :headers="headers"
            :items="authorizations"
            :loading="loading"
            :options.sync="options"
            :hide-default-footer="hideFooter"
            :server-items-length="page.totalElements"
            :footer-props="{
              itemsPerPageOptions:[],
            }"
            mobile-breakpoint="960"
          >
          <template v-slot:body="{items}" v-if="!isMobile">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.id"
              >
                <td class="primary--text" style="cursor: pointer;" @click="goToDetail(item)">{{item.id}}</td>
                <td>{{item.date}}</td>
                <td>
                  {{$t(item.type)}}                  
                </td>
                <td>{{item.beneficiaryName}}</td>
                <td>{{item.certificateNumber}}</td>
                <td>{{item.amountAuthorized}}</td>
                <td class="py-3"> 
                    <div>
                      <v-chip small label 
                        :text-color="item.statusConfiguration.textColor" 
                        :color="item.statusConfiguration.color" 
                        class="text-overline-head text-wrap"
                        v-if="item.status.length > 0"
                      >
                        {{ $t(item.status)}}
                      </v-chip>   
                      <v-icon size="16" v-if="showIcon(item.statusSlug)"
                        :color="item.iconConfiguration.color" right>
                        {{item.iconConfiguration.icon}}
                      </v-icon>
                    </div>
                    <div v-if="$vuetify.breakpoint.smAndUp && showsettlementRequired(item)">
                      <v-icon size="17" color="error">error_outline</v-icon>
                      <span style="font-size: 10px !important" class="error--text text-osg-button">{{ $t('authorizationList.status.credit-insurance.settlement-required') }}</span>
                    </div>                                               
                </td>              
              </tr>
            </tbody>
          </template>

           <template v-slot:item="{ item }" v-if="isMobile">
              <v-container>
                <v-row>
                  <v-col cols="5" class="py-0">
                    <span class="text-body-2 primary--text osg-chip-text" style="cursor: pointer;" @click="goToDetail(item)"> #{{ item.id }} </span>
                  </v-col>
                  <v-col cols="7" class="py-0 text-right">
                    <v-chip small label 
                      :text-color="item.statusConfiguration.textColor" 
                      :color="item.statusConfiguration.color" 
                      class="text-overline-head text-wrap"
                      v-if="item.status.length > 0"
                      style="font-size: 11px !important"
                    >
                      {{ $t(item.status) }}
                      <v-icon size="16" v-if="showIcon(item.statusSlug)"
                        :color="item.iconConfiguration.color" right>
                        {{item.iconConfiguration.icon}}
                      </v-icon>
                    </v-chip>
                  </v-col>
                </v-row>
                
                <v-row>
                  <v-col cols="12" sm="6" class="py-0">
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <span class="text-button osg-chip-text"> {{$t('authorizationList.header.date')}}</span> 
                        <span class="osg-chip-text"> {{ item.date }}</span>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <span class="text-button osg-chip-text">{{$t('authorizationList.header.type')}}</span> 
                        <span class="osg-chip-text"> {{ $t(item.type) }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" class="text-right pb-0 pt-2" v-if="$vuetify.breakpoint.smAndUp && showsettlementRequired(item)">
                    <v-icon size="20" color="error">error_outline</v-icon>
                    <span class="pl-2 error--text text-osg-button">{{ $t('authorizationList.status.credit-card-balance.settlement-required') }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0" v-if="showAmountAuthorized(item)">
                    <span class="text-button ">{{$t(getAmountText(item))}}</span> 
                    <span class="text-subtitle-2"> {{ item.amountAuthorized }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" class="py-0" v-if="$vuetify.breakpoint.xsOnly && showsettlementRequired(item)">
                    <v-icon size="20" color="error">error_outline</v-icon>
                    <span class="pl-2 error--text text-osg-button">{{ $t('authorizationList.status.credit-card-balance.settlement-required') }}</span>
                  </v-col>
                </v-row>
              </v-container>
              <v-divider></v-divider>
           </template>

          </v-data-table>
          <v-row v-else>
            <v-col cols="12" class="d-flex justify-center">
              <img :src="`${publicPath}emptyState.png`" alt="empty state">
            </v-col>
            <v-col cols="12" class="d-flex align-center flex-column">
              <span class="secondary--text text-sm-h4 text-h5" v-if="$vuetify.breakpoint.mdAndUp">{{$t("emptyList.desktop")}}</span>
              <span class="secondary--text text-sm-h4 text-h5" v-if="$vuetify.breakpoint.smAndDown">{{$t("emptyList.mobile.prefix")}}</span>
              <span class="secondary--text text-sm-h4 text-h5" v-if="$vuetify.breakpoint.smAndDown">{{$t("emptyList.mobile.sufix")}}</span>
              <div v-if="$vuetify.breakpoint.mdAndUp">
                 <p class="osg-gray--text">
                  {{$t("emptyListDescription.prefix")}} 
                  {{$t("emptyListDescription.middle")}}
                  <span @click="cleanAll" class="primary--text" style="cursor:pointer; font-weight:500">{{$t("authorizationList.cleanFilters")}}</span>
                  {{$t("emptyListDescription.sufix")}}
                </p>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex align-center flex-column" v-if="$vuetify.breakpoint.smAndDown">
              <span class="osg-gray--text" style="text-align: justify;">
                <p style="font-size: 14px">
                  {{$t("emptyListDescription.prefix")}} <br/>
                  {{$t("emptyListDescription.middle")}}
                  <span @click="cleanAll" class="primary--text" style="cursor:pointer; font-weight:500">{{$t("authorizationList.cleanFilters")}}</span>
                  {{$t("emptyListDescription.sufix")}}
                </p> 
              </span>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row align="center" v-if="itemsPerRowVisible">
      <v-pagination
        v-model="actualPage"
        :length="page.totalPages"
        :total-visible="5"    
        @input="onChangePage"  
        v-if="hideFooter"
        class="osg-pagination"  
      ></v-pagination>
      <v-col class="text-right">
        <span class="text-body-2 osg-black--text">{{ $t('authorizationList.itemsPerPage') }}</span>
      </v-col>
      <v-col cols="1">
        <v-select :items="itemsPerPage" v-model="selectedItemsPerPage" @change="onChangeItemsPerPage">
        </v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { creditInsurance } from '@/utils'
import { createNamespacedHelpers } from 'vuex'
const authorizationModule = createNamespacedHelpers('authorization');

export default {
  name: 'AuthorizationList',
  props: {
    authorizations: Array,
    subtitle: String,
    loading: Boolean,
    hideFooter: {
      type: Boolean,
      default: true
    },
    pagination: Number
  },
  data() {
    return {
      options: {},
      itemsPerPage: [12,24,32],
      selectedItemsPerPage: 12,
      actualPage: this.pagination,
      sort: {
        value: '',
        isDesc: ''
      },
      publicPath: process.env.BASE_URL,
      creditInsurance
    }
  },
  computed: {
    ...authorizationModule.mapGetters(['page']),
    headers() {
      return [
        {
          text: this.$t('authorizationList.header.id'), 
          value: 'id', 
          class: "secondary--text", 
          sortable:false
        },
        {
          text: this.$t('authorizationList.header.date'), 
          value: 'date', 
          class: "secondary--text", 
          sortable:false,
        },
        {
          text: this.$t('authorizationList.header.coverage'), 
          value: 'coverage', 
          class: "secondary--text", 
          sortable:false,
        },
        {
          text: this.$t('authorizationList.header.beneficiaryName'), 
          value: 'beneficiaryName', 
          class: "secondary--text", 
          sortable:false,
        },
        {
          text: this.$t('authorizationList.header.certificateNumber'), 
          value: 'certificateNumber', 
          class: "secondary--text", 
          sortable:false,
        },
        {
          text: this.$t('authorizationList.header.amountAuthorized'), 
          value: 'amountAuthorized', 
          class: "secondary--text", 
          sortable:false
        },
        {
          text: this.$t('authorizationList.header.status'), 
          value: 'status', 
          class: "secondary--text", 
          sortable:false
        },
      ]
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    itemsPerRowVisible() {
      return !this.loading && this.$vuetify.breakpoint.mdAndUp && this.authorizations.length > 0
    }
  },
  watch: {
    options: {
      handler ($event) {
         this.onChangePage($event.page)
      },
      deep: true,
    },
  },
  methods: {
    ...authorizationModule.mapActions(['setSingleAuthorization']),
    onChangePage(page){
      this.actualPage = page
      this.$emit('onChangePage', page);
    },
    onChangeItemsPerPage(){
      this.$emit('onChangeItemsPerPage', this.selectedItemsPerPage)
    },
    showAmountAuthorized(item) {
      return ((item.statusSlug === 'CLAIMED' && item.productStatus !== 'REJECTED') || item.statusSlug === 'PAID')
    },
    getAmountText(item) {
      if (item.statusSlug === 'CLAIMED' && item.productStatus !== 'REJECTED') {
        return 'authorizationList.header.amountAuthorized'
      } else if (item.statusSlug === 'PAID') {
        return 'authorizationList.header.refund'
      }
    },
    showIcon(status) {
      return status === 'CLAIMED' || status === 'READY'
    },
    showsettlementRequired(item) {
      return item.statusSlug === 'PAID' && this.creditInsurance.includes(item.coverageType)
    },
    goToDetail(authorization) {
      this.setSingleAuthorization(authorization)
      this.$router.push('/authorization/' + authorization.id);
    },
    cleanAll(){
      this.$emit("cleanAll")
    }
  },
  updated() {
    this.options.itemsPerPage = this.page.size
    this.options.page = this.pagination
  }
}
</script>

<style lang="scss">
.osg-empty-container {
  width: 100%;
  height: 60px;
  border-radius: 5px;
  margin-left: 0px;
  background-color: #f4f5f7;
}
.v-data-footer {
  padding: 0px;
}

.v-data-footer__pagination {
  display: flex !important;
  flex: auto;
}

.v-data-table-header-mobile{
  display: none !important;
}
.v-data-table-header > tr > th {
  border-bottom: 3px solid rgba(0, 0, 0, 0.12) !important;
  height: 30px !important;
}
.osg-pagination {
    width: auto;
    margin-left: auto;
}

.osg-pagination .v-pagination__navigation {
  height: 26px !important;
  width: 26px !important;
}

.osg-pagination .v-pagination__navigation .v-icon {
  font-size: 16px !important;
}

.osg-pagination .v-pagination__item {
  height: 26px !important;
  min-width: 26px !important;
  font-size: 0.85rem !important;
}

.v-data-table td{
  font-size: 0.85rem !important;
}

.osg-chip-text { 
  font-size: 13px !important;
}
</style>