<template>
  <v-container fluid class="py-0">
    <v-row style="background-color: #FAFAFA">
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 10 : 12" :class="['py-0', { 'ma-auto': $vuetify.breakpoint.mdAndUp}]" >
        <v-row v-if="alert">
          <v-col cols="12">
            <v-alert
              v-model="alert"
              dismissible
              dense
              border="left"
              elevation="2"
              :type="alertType"
              colored-border
            >{{ message }}</v-alert>
          </v-col>
        </v-row>

        <v-row align="center" v-if="$vuetify.breakpoint.mdAndUp">
          <v-col md="6" class="d-flex align-center">
            <span class="secondary--text text-sm-h3 text-h4">{{ $t('authorizationList.title') }}</span>
            <v-btn
              depressed
              color="primary"
              class="ml-4 rounded"
              :disabled="!sponsorSlug"
              @click="onNewAuthorization"
              v-if="$vuetify.breakpoint.mdAndUp"
            >
              <v-icon small>mdi-plus</v-icon>
              <span class="pl-1">Nueva</span>
            </v-btn>
          </v-col>
          <v-col md="6" v-if="$vuetify.breakpoint.mdAndUp && graphData">
            <div class="d-flex align-center justify-end">
              <div class="d-flex flex-column mr-4" @click="openDetail">
                <span class="text-subtitle-2" style="color:#4F4F4F; line-height: 16px; cursor: pointer;">{{ $t('authorizationList.graph.remanentLabel') }}</span>
                <span class="text-button text-start osg-gray--text" style="font-weight: normal;cursor: pointer;">
                  {{ $t('authorizationList.graph.available') }} {{ generalBalance.currencyCode }} {{ parseFloat(generalBalance.maximumBenefitAvailable).toLocaleString() }}
                </span>
              </div>
              <RemanentCard type="horizontalBar" :graphData="graphData" />
            </div>
          </v-col>
        </v-row>

        <v-row align="center" v-if="$vuetify.breakpoint.smAndDown">
          <v-col class="d-flex justify-content-center">
            <span class="secondary--text text-sm-h3 text-h4">{{ $t('authorizationList.title') }}</span>
          </v-col>
          <v-col cols="4">
            <v-btn
              depressed
              color="primary"
              class="rounded"
              :disabled="!sponsorSlug"
              @click="onNewAuthorization"
              block
            >
              <v-icon small>mdi-plus</v-icon>
              <span class="pl-1">Nueva</span>
            </v-btn>
          </v-col>
        </v-row>

        <v-row align="center" v-if="$vuetify.breakpoint.mdAndUp">
          <v-col cols="4">
            <v-text-field
              hide-details
              outlined
              dense
              type="text"
              name="searchInput"
              class="rounded"
              append-icon="mdi-magnify"
              :placeholder="$t('authorizationList.filters.inputSearchPlaceholder')"
              style="background-color: white"
              @input="searchFunction"
            >
            </v-text-field>
          </v-col>
          <v-col class="text-right">
            <span class="text-subtitle-2 osg-black--text">{{ $t('authorizationList.filters.filterByLabel') }}</span>
          </v-col>
          <v-col>
            <v-select
              v-model="selectedTypes"
              :items="coverageTypes"
              :menu-props="{ maxHeight: '400' }"
              background-color="white"
              :dense="!$vuetify.breakpoint.smAndDown"
              @change="onSelectedTypes"
              return-object
              outlined
              hide-details
              class="py-0 rounded"
              :label="$t('authorizationList.header.coverage')"
              multiple
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">
                  <span>{{ $t(item.text) }}</span>
                </span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption ml-1"
                >
                  (+{{ selectedTypes.length - 1 }})
                </span>
              </template>
            </v-select>
          </v-col>
          <v-col>
            <v-select
              dense
              multiple
              outlined
              hide-details
              class="py-0 rounded"
              :items="states"
              :menu-props="{ maxHeight: '400' }"
              :label="$t('authorizationList.filters.stateFilterLabel')"
              @change="onSelectedStates"
              background-color="white"
              v-model="selectedStates"
              return-object
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">
                  <span>{{ $t(item.text) }}</span>
                </span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption ml-1"
                >
                  (+{{ selectedStates.length - 1 }})
                </span>
              </template>
            </v-select>
          </v-col>
          <v-col>
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    hide-details
                    readonly
                    clearable
                    :label="$t('authorizationList.dateFilter')"
                    v-model="dateRangeText"
                    background-color="white"
                    class="py-0 rounded"
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="clearDates"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dates"
                  range
                  @change="selectedDate"
                  color="primary"
                  no-title
                ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row align="center" v-if="$vuetify.breakpoint.smAndDown">
          <v-col>
            <v-text-field
              hide-details
              outlined
              dense
              type="text"
              name="searchInput"
              class="rounded"
              append-icon="mdi-magnify"
              :placeholder="$t('authorizationList.filters.inputSearchPlaceholder')"
              style="background-color: white"
              @input="searchFunction"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <FiltersAppliedMobile
              :coverageFilters="coverageTypes"
              :orderByItems="orderByItems"
              :statusFilters="states"
              @applyFilters="applyFilters"
              :cleaningTrigger="cleaningTrigger"
            />
          </v-col>
        </v-row>

      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown && graphData">
      <v-col class="py-0 ">
        <RemanentCard type="doughnut" :balance="generalBalance" :graphData="graphData" @openDetail="openDetail"/>
      </v-col>
    </v-row>

    <v-row v-if="$vuetify.breakpoint.smAndDown && !authorizationLoading" style="background-color: #F2F2F2">
      <span :class="['secondary--text', 'text-h7', 'subtitle-1', 'px-6', 'py-3']">
          {{totalResultText}}
      </span>
    </v-row>

    <v-row style="background-color: #F2F2F2" v-if="!authorizationLoading && $vuetify.breakpoint.mdAndUp">
      <v-col cols="10" :class="['py-0', 'pl-6', { 'ma-auto': $vuetify.breakpoint.mdAndUp}]" >

        <v-row align="center">
          <span :class="['secondary--text', 'text-h7', 'subtitle-1']">
              {{totalResultText}}
          </span>
          <v-col class="d-flex flex-direction-row">
            <FiltersApplied
              :coverageFilters="coverageFilterList"
              :statusFilters="statusFilterList"
              :selectedDates="selectedDateChip"
              @removeCoverageFilter="removeCoverageFilter"
              @removeStatusFilter="removeStatusFilter"
              @removeSelectedDates="removeSelectedDates"
              @cleanAll="cleanAll"
            />
          </v-col>
          <span class="text-subtitle-2 osg-black--text">{{ $t('authorizationList.orderBy') }}</span>
          <v-col cols="2">
            <v-select
              v-model="orderSelected"
              :items="orderByItems"
              @change="onOrderSelected"
              :menu-props="{ maxHeight: '400' }"
              background-color="white"
              dense
              return-object
              outlined
              hide-details
              class="py-0 rounded"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        :cols="colsAuthorizationList"
        :class="['py-0', { 'ma-auto': $vuetify.breakpoint.mdAndUp}]">
        <v-container class="py-0 mt-6">
          <AuthorizationList
            :authorizations="authorizations"
            :loading="authorizationLoading"
            @onChangePage="onChangePage"
            @onChangeItemsPerPage = "onChangeItemsPerPage"
            @cleanAll="cleanAll"
            :hide-footer="$vuetify.breakpoint.mdAndUp && page.totalPages > 1"
            :pagination="this.filters.page"
          />
        </v-container>
      </v-col>
    </v-row>
    <RemanentDetail v-if="remanentDetail.showModal"
      :remanentDetail="remanentDetail" />
  </v-container>
</template>

<script>
import { creditInsurance, secondAssessmentCoverageType } from '@/utils'
import arcService from '@/services/arc-api.service'
import plansService from '@/services/plans-api.service'
import moment from 'moment';
import AuthorizationList from './AuthorizationList'
import RemanentCard from './remanent/general/RemanentCard'
import RemanentDetail from './remanent/detail/RemanentDetail'
import FiltersApplied from './Filters/FiltersApplied';
import FiltersAppliedMobile from './Filters/FiltersAppliedMobile'
import { createNamespacedHelpers } from 'vuex'
import Vue from 'vue';
const authorizationModule = createNamespacedHelpers('authorization');
const authModule = createNamespacedHelpers('auth');

export default {
  name: 'AuthorizationListContainer',
  components: { AuthorizationList, RemanentCard, RemanentDetail, FiltersApplied, FiltersAppliedMobile },
  data: () => ({
    selectedStates: [],
    selectedTypes: '',
    dates: [],
    alert: false,
    alertType: 'error',
    message: '',
    menu: false,
    filters: {
      beneficiaryId: 0,
      statuses: '',
      types: '',
      fromDate: '',
      toDate: '',
      page: 1,
      size: 12,
      searchValue: '',
      orderBy: 'createdAt',
      sort: 'DESC'
    },
    remanentDetail: {
      showModal: false
    },
    coverageFilterList : [],
    statusFilterList: [],
    filtersMobile: false,
    orderSelected: "createdAt",
    typingTimer: "",
    selectedDateChip: '',
    cleaningTrigger: new Vue(),
    creditInsurance
  }),
  created() {
    this.filters.beneficiaryId = this.tokenExtensions.beneficiaryId;
    this.resetSearch();
    if (!this.sponsorSlug) {
      this.getBeneficiary();
    }

    if (this.reimbursementStatus === 'SUCCESS') {
      this.alert = true;
      this.alertType = 'success';
      this.message = this.$i18n.t('addAuthorization.successLabel')
      setTimeout(() => {
        this.alert = false;
      }, 10000)
      this.setReimbursementStatus(null)
    }

    if(this.reimbursementStatus === 'ERROR'){
      this.alert = true;
      this.alertType = 'error';
      this.message = this.$i18n.t('addAuthorization.errorLabel')
      if (this.getError.hasError) {
        this.message = this.getError.error
      }
      setTimeout(() => {
        this.alert = false;
        this.setError({hasError: false, error: null})
      }, 10000)
      this.setReimbursementStatus(null)
    }
    this.getAuthorizationsForm()
  },
  methods: {
    ...authorizationModule.mapActions(['getAuthorizations', 'getBeneficiary', 'setReimbursementStatus', 'setError','getAuthorizationsForm']),
    getFormattedPickerDates: function() {
      if(this.dates[0] > this.dates[1]){
        let aux = this.dates[0];
        this.dates[0] = this.dates[1]
        this.dates[1] = aux
      }
      this.formattedDates = [];
      this.dates.forEach(date => {
        this.formattedDates.push(moment(date).format('DD/MM/YYYY'));
      });
      return this.formattedDates.join(' ~ ');
    },
    onChangeItemsPerPage(itemsPerPage) {
      this.filters.size = itemsPerPage;
      this.loadAuthorizations();
    },
    onSelectedStates(status) {
      this.statusFilterList = status;
      this.filters.statuses = status.map(status => status.value).join(',')
      this.resetSearch();
    },
    onSelectedTypes(type) {
      this.coverageFilterList = type;
      this.filters.types = type.map(type => type.value).join(',')
      this.resetSearch();
    },
    onChangePage(page){
      this.filters.page = page;
      this.loadAuthorizations();
    },
    resetSearch() {
      this.filters.page = 1;
      this.loadAuthorizations();
    },
    loadAuthorizations() {
      this.getAuthorizations({...this.filters, page: this.filters.page - 1});
    },
    selectedDate(date) {
      if(date[0] > date[1]){
        let aux = date[0];
        date[0] = date[1];
        date[1] = aux;
      }
      this.filters.fromDate = moment(date[0]).toISOString();
      this.filters.toDate = moment(date[1]).endOf('day').toISOString();
      this.selectedDateChip = date[0]  + " ~ " + date[1];
      this.resetSearch();
    },
    onNewAuthorization() {
      this.$router.push('/authorization/new');
    },
    cleanAll() {
      this.coverageFilterList = []
      this.statusFilterList = []
      this.selectedTypes = [];
      this.selectedStates = [];
      this.dates = [];
      this.filters.fromDate = '';
      this.filters.toDate = '';
      this.filters.statuses = '';
      this.filters.types = '';
      this.filters.orderBy = 'createdAt';
      this.filters.searchValue = '';
      this.selectedDateChip = '';
      this.cleaningTrigger.$emit('clearAll')
      this.resetSearch()
    },
    openDetail() {
      this.remanentDetail.showModal = true;
    },
    removeCoverageFilter(coverageApplied){
      const removeIndex = this.selectedStates.map(type => type.value).indexOf(coverageApplied.value);
      this.selectedTypes.splice(removeIndex, 1);
      this.filters.types = this.selectedTypes.map(type =>type.value).join(',');
      this.resetSearch();
    },
    removeStatusFilter(statusApplied){
      const removeIndex = this.selectedStates.map(status => status.value).indexOf(statusApplied.value);
      this.selectedStates.splice(removeIndex, 1);
      this.filters.statuses = this.selectedStates.map(status =>status.value).join(',');
      this.resetSearch();
    },
    applyFilters(filtersMobile){
      if(filtersMobile.clearAll){
        this.cleanAll();
      }else{
        this.filters.types = filtersMobile.coverageTypes.map(types => types.value).join(',');
        this.filters.statuses = filtersMobile.statuses.map(status => status.value).join(',');
        this.filters.orderBy = filtersMobile.orderBy;
        this.filters.sort = filtersMobile.sort;
        this.filters.fromDate = filtersMobile.fromDate;
        this.filters.toDate = filtersMobile.toDate;
        this.resetSearch();
      }
    },
    onOrderSelected(){
      this.filters.orderBy = this.orderSelected.value;
      this.filters.sort = this.orderSelected.direction;
      this.resetSearch();
    },
    searchAuthorization(e){
      clearTimeout(this.typingTimer)
      this.filters.searchValue = e;
      this.resetSearch();
    },
    searchFunction(e){
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(() => {
        this.searchAuthorization(e);
      }, 900);
    },
    clearDates(){
      this.dates = [];
      this.filters.fromDate = '';
      this.filters.toDate = '';
      this.selectedDateChip = '';
      this.resetSearch();
    },
    removeSelectedDates(){
        this.clearDates()
    }
  },
  computed: {
    ...authorizationModule.mapGetters(['authorizations', 'page',
    'authorizationLoading', 'authorizationPage', 'sponsorSlug', 'reimbursementStatus',
    'generalBalance', 'getError','authorizationForms']),
    ...authModule.mapGetters(['tokenExtensions']),
    states () {
      let selectedStates;
      if (this.authorizationForms.length != 0){
        const coverageType = this.authorizationForms[0].authorizationCoverage;
        if (this.selectedTypes.length === 0 || this.selectedTypes.length > 1) {
          if (coverageType === secondAssessmentCoverageType) {
            selectedStates = arcService.statusFiltersSecondAssessment.find(state => state.coverageType === secondAssessmentCoverageType);
          } else if (this.creditInsurance.includes(coverageType)) {
            selectedStates = arcService.status.find(state => state.coverageType === 'CREDIT_INSURANCE')
          } else {
            selectedStates = arcService.status.find(state => state.coverageType === 'DEFAULT')
          }
        }
        else{
          selectedStates = arcService.status.find(state => state.coverageType === this.selectedTypes[0].value)
          if (!selectedStates) {
            if (coverageType === secondAssessmentCoverageType) {
              selectedStates = arcService.statusFiltersSecondAssessment.find(state => state.coverageType === secondAssessmentCoverageType);
            } else if(this.creditInsurance.includes(coverageType)) {
              selectedStates = arcService.status.find(state => state.coverageType === 'CREDIT_INSURANCE')
            } else {
              selectedStates = arcService.status.find(state => state.coverageType === 'DEFAULT')
            }
          }
        }
        return selectedStates.states.map(state => ({...state, text: this.$t(state.text)}))
      }
      return []
    },
    coverageTypes() {
      const coveragesTypes = [];
      this.authorizationForms.forEach(form => {
           arcService.types.forEach(coverage => {
            if (form.authorizationCoverage === coverage.slug) {
             coveragesTypes.push(coverage);
            }
          })
      })
      return coveragesTypes.map(type => ({...type, text: this.$t(type.text)}));
    },
    dateRangeText: {
      get() {
        return this.getFormattedPickerDates();
      },
      set() {
        this.formattedDates = '';
        this.dates = [];
      }
    },
    graphData() {
      if (!this.generalBalance) {
        return;
      }

      const data = this.generalBalance;
      if (isNaN(data.maximumBenefit) || isNaN(data.maximumBenefitAvailable)) {
        return;
      }

      let graphData = {};
      const total = data.maximumBenefit;
      const available = data.maximumBenefitAvailable;
      const usedAmount = data.maximumBenefit - data.maximumBenefitAvailable;
      const availableColor = plansService.getStatusConfiguration('AVAILABLE').color;
      const usedColor = plansService.getStatusConfiguration('USED').color;
      const availableLabel = this.$t('authorizationList.graph.available');
      const usedLabel = this.$t('authorizationList.graph.used');

      const type = this.$vuetify.breakpoint.mdAndUp ? 'bar' : 'doughnut'
      graphData = plansService.getGeneralBalanceData({
        type,
        total, availableLabel, availableColor, available,
        usedLabel, usedAmount, usedColor
      });

      return graphData;
    },
    totalResultText() {
      const result = typeof this.page.totalElements === 'undefined' ? 0 : this.page.totalElements;
      return this.$tc("authorizationList.resultsTitle", result, {result});
    },
    orderByItems() {
        return arcService.orderBy.map(order => ({...order, text: this.$t(order.text)}));
    },
    colsAuthorizationList(){
      if(this.$vuetify.breakpoint.lgAndUp){
        return 10
      }else{
        return 12
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.select-status-item {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #e0e0e0;
}
</style>
