<template>
  <div :class="{'d-flex justify-end': type === 'horizontalBar', 'px-4 py-4': type !== 'horizontalBar'}">
    <v-card class="graph-card"
      :width="type === 'horizontalBar' ? 258 : ''" 
      :height="type === 'horizontalBar' ? 50 : ''">
      <v-row class="ma-auto">
        <v-col :cols="type === 'horizontalBar' ? 11 : 4 " :class="{'pa-0 px-2 ma-auto': type === 'horizontalBar', 'pa-0 d-flex justify-center align-center': type !== 'horizontalBar'}">
          <RemanentGraph 
          :style="localStyles"
          :width="type === 'horizontalBar' ? localWidth : null" 
          :height="type === 'horizontalBar' ? localHeight : null"
          :graphId="Math.random() * (1000 - 1) + 1"  
          :graphData="graphData"
          :type="type" />
        </v-col>
        <v-col :cols="type === 'horizontalBar' ? 7 : 8 " :class="{'pa-0': type === 'horizontalBar'}" v-if="type !== 'horizontalBar'">
          <v-row dense>
            <v-col class="pa-0" style="line-height: 1.2">
              <span class="text-body-1">
                {{ balance.currencyCode }} {{ parseFloat(balance.maximumBenefit - balance.maximumBenefitAvailable).toLocaleString() }} 
              </span>
              <span class="primary--text">
                ({{ (((balance.maximumBenefit - balance.maximumBenefitAvailable) / balance.maximumBenefit) * 100).toFixed(1) }}%)
              </span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="pa-0" style="line-height: 1.2">
              <span class="text-caption" style="color: #828282;">
                {{ $t('authorizationList.graph.available') }} ({{ balance.currencyCode }} {{ parseFloat(balance.maximumBenefitAvailable).toLocaleString() }})
              </span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="pa-0 primary--text" style="line-height: 1.2">
              <span class="text-subtitle-2" @click="openDetail">{{ $t('remanentCard.detailLabel') }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import RemanentGraph from './RemanentGraph'
export default {
  name: 'RemanentCard',
  components: { RemanentGraph },
  props: {
    type: {
      type: String,
      default: 'horizontalBar'
    },
    graphData: {
      type: Object,
      default() {
        return {};
      }
    },
    balance: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      styleHorizontal: {
        maxWidth: '240px',
        maxHeight: '50px'
      },
      styleDoughnut: {
        width: '120px',
        height: '86px'
      }
    }
  },
  methods: {
    openDetail() {
      this.$emit('openDetail');
    }
  },
  computed: {
    localStyles() {
      if (this.type === 'horizontalBar') {
        return this.styleHorizontal;
      } else {
        return this.styleDoughnut;
      }
    },
    localWidth() {
      if (this.type === 'horizontalBar') {
        return parseInt(this.styleHorizontal.maxWidth.replace('px', ''));
      } else {
        return parseInt(this.styleDoughnut.width.replace('px', ''));
      }
    },
    localHeight() {
      if (this.type === 'horizontalBar') {
        return parseInt(this.styleHorizontal.maxHeight.replace('px', ''));
      } else {
        return parseInt(this.styleDoughnut.height.replace('px', ''));
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.graph-card {
  border-radius: 8px !important;
  box-shadow: 0 8px 16px 0 rgba(0, 74, 148, 0.13) !important;
}
</style>