<template>
    <v-row no-gutters>
        <v-chip 
            v-for="filter in coverageFilters" 
            :key="filter.value"
            close
            @click:close="removeCoverageFilter(filter)"
            :class="['mr-3', 'mt-2', { 'mt-3': $vuetify.breakpoint.smAndDown}]"
        >
            <strong class="osg-gray--text" v-if="$vuetify.breakpoint.mdAndUp">
                {{$t("authorizationList.header.coverage")}}
            </strong>
            <span v-if="$vuetify.breakpoint.mdAndUp">:</span>
            <span :class="['osg-gray--text', {'ml-1': $vuetify.breakpoint.mdAndUp}]">
                {{filter.text}}
            </span>
        </v-chip>
        <v-chip 
            v-for="status in statusFilters" 
            :key="status.value"
            close
            @click:close="removeStatusFilter(status)"
            :color="status.color"
            :text-color="status.textColor"
            :class="['mr-3', 'mt-2', { 'mt-3': $vuetify.breakpoint.smAndDown}]"
        >
            {{status.text}}
        </v-chip>
        <v-chip 
            v-if="selectedDates !== ''"
            close
            @click:close="removeSelectedDates()"
            :class="['mr-3', 'mt-2', { 'mt-3': $vuetify.breakpoint.smAndDown}]"
        >
        <span class="osg-gray--text">{{selectedDates}}</span>
        </v-chip>
        <v-btn
            text 
            depressed color="primary" 
            @click="cleanAll"
            v-if="cleanAllVisibility"
        >
            {{ $t('authorizationList.cleanFilter') }}
        </v-btn>
    </v-row>    
</template>
<script>
    export default {
        name: "FiltersApplied",
        props: {
            coverageFilters: Array,
            statusFilters: Array,
            selectedDates: String
        },
        methods: {
            removeCoverageFilter(filter){
                this.$emit('removeCoverageFilter', filter);
            },
            removeStatusFilter(status){
                this.$emit('removeStatusFilter', status);
            },
            removeSelectedDates(){
                this.$emit('removeSelectedDates');
            },
            cleanAll(){
                this.$emit('cleanAll')
            }
        },
        computed: {
            cleanAllVisibility(){
                return (this.coverageFilters.length > 0 || this.statusFilters.length > 0) && this.$vuetify.breakpoint.mdAndUp
            }
        }
    }
</script>
