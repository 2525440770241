<template>
  <div class="pa-0 d-flex justify-center align-center">
    <canvas :id="graphId" :style="{'width': width + 'px', 'height': height + 'px'}"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js';

export default {
  name: 'RemanentGraph',
  props: {
    width: {
      type: Number,
      default: 250
    },
    height: {
      type: Number,
      default: 250
    },
    type: {
      type: String,
      default: 'horizontalBar'
    },
    graphId: {
      type: Number,
      default: Math.random() * (1000 - 1) + 1
    },
    graphData: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  data() {
    return {
      vueCanvas: null,
      graph: null,
    }
  },
  mounted() {
    var graph = document.getElementById(this.graphId);
    var ctx = graph.getContext('2d');
    this.vueCanvas = ctx;

    if (this.type === 'horizontalBar') {
      new Chart(this.vueCanvas, {
        type: 'horizontalBar',
        data: this.graphData,
        options: {
          tooltips: {enabled: false},
          title: {
            display: false
          },
          legend: {
            display: true,
            fullWidth: true,
            align: 'start',
            labels: {
              padding: 5,
              boxWidth: 5,
              usePointStyle: true,
              fontFamily: 'Open Sans',
              fontSize: 13
            },
            onClick: (e) => e.stopPropagation()
          },
          responsive: true,
          scales: {
            xAxes: [
              { 
                display: false,
                stacked: true,
                gridLines: {
                  display: false,
                },
                scaleLabel: {
                  display: false
                }
              }
            ],
            yAxes: [
              { 
                display: false,
                stacked: true,
                gridLines: {
                  display: false,
                },
                scaleLabel: {
                  display: false
                }
              }
            ]
          }
        },
        plugins: [
          {
            beforeInit: function(chart) {
              chart.legend.afterFit = function() {
                this.height = this.height + -10;
              }
            }
          }
        ]
      })
    } else if (this.type === 'bar') {
      this.graph = new Chart(this.vueCanvas, {
        type: 'bar',
        data: this.graphData,
        options: {
          curvature: 1,
          title: {
            display: false
          },
          legend: {
            display: false,
          },
          responsive: true,
          scales: {
            xAxes: [
              { 
                display: false,
                stacked: true,
              }
            ],
            yAxes: [
              { 
                display: false,
                stacked: true,
              }
            ]
          }
        },
      })
    } else {
      new Chart(this.vueCanvas, {
        type: 'doughnut',
        data: this.graphData,
        options: {
          title: {
            display: false,
          },
          legend: {
            display: false
          },
          responsive: true,
          cutoutPercentage: 80,
          animation: {
            animateScale: true,
            animateRotate: true
          },
        },
        plugins: [
          {
            beforeDraw: function(chart) {
              var width = chart.chart.width,
                  height = chart.chart.height,
                  ctx = chart.chart.ctx;

              ctx.restore();
              var fontSize = (height / 65).toFixed(2);
              ctx.font = fontSize + "em canaro";
              ctx.textBaseline = "middle";

              var text = chart.config.data.doughnutLabel,
                  textX = Math.round((width - ctx.measureText(text).width) / 2),
                  textY = height / 2;

              ctx.fillText(text, textX, textY);
              ctx.save();
            }
          }
        ]
      })
    }   
  },
  methods: {
    updateGraph() {
      this.graph.update();
    },
  },
}
</script>