<template>
  <div>
    <AuthorizationListContainer/>
  </div>
</template>

<script>
import AuthorizationListContainer from '@/components/authorization/AuthorizationListContainer'
export default {
    name: 'Home',
    components: {
        AuthorizationListContainer
    }
}
</script>